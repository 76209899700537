import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Jumbotron from '../../components/Jumbotron';
import { SECONDARY } from '../../utils/colors';
import Thumb from '../../assets/svgs/thumbContact.svg';
import ContactForm from '../../components/ContactForm';
import CopyButton from '../../components/CopyButton';

const ContactPage = () => (
  <Layout theme="blue">
    <SEO
      title="Fale com a Central do Frete"
      description="Fale com a Central do Frete e lhe ajudamos a tirar dúvidas e fazer sua cotação de frete muito mais rápida e descomplicada."
    />
    <Jumbotron
      Image={Thumb}
      background={SECONDARY}
      title="Fale conosco"
      subtitle="Não fique na dúvida, envie uma mensagem para a Central do Frete."
    />
    <Container className="pt-5 pb-5">
      <Row>
        <Col xs="12" md="4">
          <section>
            <header>
              <h3 className="text-primary">Endereço</h3>
            </header>
            <section>
              <address>
                Alameda Terracota, 215, conj. 1120 Torre Union - Cerâmica - São
                Caetano do Sul - SP CEP 09531-190
              </address>
            </section>
          </section>

          <section className="pt-3">
            <header>
              <h3 className="text-primary">E-mail</h3>
            </header>
            <section>
              <a href="mailto:falecom@centraldofrete.com">
                falecom@centraldofrete.com
              </a>
              <CopyButton text="falecom@centraldofrete.com" />
            </section>
          </section>
        </Col>
        <Col xs="12" md="8">
          <ContactForm />
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default ContactPage;
